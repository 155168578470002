import { createI18n } from 'vue-i18n'

// Import language files directly
import enUS from './locales/en-US.json'
import itIT from './locales/it-IT.json'
import esES from './locales/es-ES.json'
import frFR from './locales/fr-FR.json'

const i18n = createI18n({
  legacy: false,
  locale: process.env.VUE_APP_I18N_LOCALE || 'en-US',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en-US',
  globalInjection: true,
  compositionOnly: false,
  messages: {
    'en-US': enUS,
    'it-IT': itIT,
    'es-ES': esES,
    'fr-FR': frFR
  }
})

export default i18n
