<template>
  <Button 
    class="signin-signup-button-text" 
    type="button"
    @click="signInWithGoogle"
    :label="$t('components.templates.sign.signup.signupWithGoogleButtonLabel')"
    :icon="'pi pi-google'"
  />
</template>

<script>
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth'
import { useRouter } from 'vue-router'
import Button from 'primevue/button'
import { useStore } from 'vuex'

export default {
  name: 'GoogleSignIn',
  components: {
    Button
  },
  setup() {
    const router = useRouter()
    const store = useStore()
    const auth = getAuth()
    const provider = new GoogleAuthProvider()
    
    // Add calendar scopes
    provider.addScope('https://www.googleapis.com/auth/calendar')
    provider.addScope('https://www.googleapis.com/auth/calendar.events')
    
    const clientId = '{{ your-client-id }}.apps.googleusercontent.com'
    
    const signInWithGoogle = async () => {
      try {
        console.debug('Attempting Google sign in with calendar access...');
        const result = await signInWithPopup(auth, provider)
        const user = result.user
        
        // Get access token which will include calendar permissions
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        
        // Store the access token for later calendar API use
        await store.commit('setGoogleAccessToken', accessToken)
        
        // Wait for token
        console.debug('Getting ID token...');
        const token = await user.getIdToken(true)
        console.debug('Token received, updating store...');
        
        // Update store with user
        await store.commit('setUser', user)
        
        // Wait a moment for auth state to propagate
        await new Promise(resolve => setTimeout(resolve, 500))
        
        console.debug('Google sign in successful, redirecting to businesses');
        router.push('/businesses')
      } catch (error) {
        console.error('Google sign in error:', error)
      }
    }

    return {
      signInWithGoogle
    }
  }
}
</script> 